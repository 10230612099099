<template>
  <v-card flat>
    <v-container class="pa-6">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Nome da Mãe"
          outlined
          placeholder=""
          required
          rounded
          @change="clearError"
        />

        <v-text-field
          v-model="document"
          :rules="documentRules"
          label="Nº do RG da Mãe"
          outlined
          placeholder="Sem traços e sem pontos"
          required
          rounded
          @change="clearError"
        />

        <v-text-field
          v-model.number="qtdeFilhos"
          v-mask="'##'"
          :rules="qtdeFilhosRules"
          label="Quantidade de Filhos"
          type="number"
          required
          outlined
          rounded
          @change="clearError"
        />

        <v-text-field
          v-model="phone"
          v-mask="'(##)#####-####'"
          :rules="phoneRules"
          placeholder="(11)99999-9999"
          label="Telefone"
          required
          outlined
          rounded
          type="tel"
          @change="clearError"
        />

        <!-- <v-checkbox
          v-model="isWhatsApp"
          label="Esse número de telefone possui Whatsapp?"
          required
        /> -->
        <v-radio-group
          v-model="isWhatsApp"
          :rules="[(v) => v.length > 0 || 'Ops! Informe se o telefone é WhatsApp.']"
          class="pt-0 pb-0"
          @change="clearError"
        >
          <template v-slot:label>
            <div class="pt-0 pb-0">Esse número de telefone possui <strong>Whatsapp?</strong></div>
          </template>
          <v-radio
            label="Não"
            value=false
          ></v-radio>
          <v-radio
            label="Sim"
            value=true
          ></v-radio>
        </v-radio-group>

        <v-row v-if="error || !valid">
          <v-col cols="12">
            <div class="red--text text-center aling-center">
              {{ error }}
            </div>
          </v-col>
        </v-row>

        <v-btn
          :disabled="!valid || loading"
          :loading="loading"
          color="orange darken-3"
          :dark="!error && valid"
          class="mr-4"
          block
          rounded
          @click="acceptRules"
        >
          CADASTRAR
        </v-btn>
      </v-form>
    </v-container>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Regras para o Cadastro
        </v-card-title>

        <v-card-text style="height: 400px;">
          <div v-html="config && config.subscribe && config.subscribe.rules ? config.subscribe.rules : ''"/>
          <!-- <p class="pt-6">a)	Crianças até 10 (dez) anos completos até dia 31/12/2021.</p>
          <p>b)	A família <strong>NÃO PODE</strong> ser proprietária de automóvel ou moto (de qualquer tipo e ano), o que demonstra poder aquisitivo bastante para comprar seus próprios presentes.</p>
          <p>c)	Se tiver alguém da família trabalhando, não poderá fazer o cadastro.</p>
          <p>d)	Famílias que estejam inscritas ou se inscrevam até o final deste ano em outros lugares que também distribuam sacolinhas.</p>
          <p>e)	Cadastros duplicados serão automaticamente excluídos.</p>
          <p class="font-weight-bold">PENSE BEM: </p>
          <p>•	Se você (mãe ou responsável) tem condições de presentear seu filho ou filha no Natal, tenha consciência e <strong>NÃO TIRE A OPORTUNIDADE </strong> de uma outra família realmente necessitada.</p>
          <p>•	Mesmo que você faça o Cadastro, se descobrirmos que <strong>NÃO</strong> se enquadra em qualquer das regras acima, você será excluída automaticamente da relação dos participantes. </p>
          <p>•	Não se esqueça que outras pessoas te conhecem e certamente nos falarão a verdade. </p> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="cancel"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="validate"
          >
            Concordo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  import { setMother, isMotherDuplicated } from '../services/mothers'
  import { customAlphabet } from 'nanoid/non-secure'
  import { formatPhone } from '../utils/phone'
  import { mapState } from 'vuex'
  export default {
    name: 'SubscribeForm',
    data: () => ({
      valid: true,
      name: '',
      nameRules: [
        (v) => !!v || 'Ops! Informe seu nome completo.',
        (v) => (v && v.length >= 10) || 'Ops! Informe seu nome completo.',
      ],
      document: '',
      documentRules: [
        (v) => !!v || 'Ops! Informe seu RG.',
        (v) => (v && v.length >= 6) || 'Ops! Informe seu RG.',
      ],
      phone: '',
      phoneRules: [
        (v) => !!v || 'Ops! Informe seu WhatsApp ou telefone.',
        (v) => (v && v.length >= 10) || 'Ops! Informe seu telefone.',
      ],
      qtdeFilhos: '',
      qtdeFilhosRules: [
        (v) => !!v || 'Ops! Informe a quantidade de filhos.',
        (v) => (v && v >= 0) || 'Ops! Informe a quantidade de filhos.',
      ],
      // email: "",
      // emailRules: [
      //   (v) => !!v || "E-mail is required",
      //   (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      // ],
      isWhatsApp: true,
      subscriptionRules: true,
      error: null,
      loading: false,
      dialog: false
    }),
    computed: {
      ...mapState(['config', 'selectedYear'])
    },
    methods: {
      async validate () {
        if (!this.$refs.form.validate()) {
          this.error = 'Informe os dados solicitados'
          this.dialog = false
          return
        }
        this.loading = true
        if(await isMotherDuplicated(this.document, this.selectedYear)) {
          this.error = 'Mãe já está inscrita'
          this.dialog = false
          this.loading = false
          return
        }

        const token = customAlphabet('1234567890', 4)();
        const mother = {
          created_at: new Date(),
          updated_at: new Date(),
          status: 'cadastro',
          name: this.name,
          document: this.document,
          phone: formatPhone(this.phone),
          sons: this.qtdeFilhos,
          isWhatsApp: this.isWhatsApp === "true",
          type: 'mother',
          token: token,
          yearParty: (new Date()).getFullYear()
        }
        setMother(mother)
          .then(() => {
            this.$store.commit('setMother', mother)
            this.$router.push('/cadastro-realizado')
          })
          .catch(err => {
            console.error(err)
            this.error = 'Erro ao realizar o cadastro'
          })
      },
      async acceptRules() {
        if (!this.$refs.form.validate()) {
          this.error = 'Informe os dados solicitados'
          this.dialog = false
          return
        }
        this.loading = true
        let duplicated = await isMotherDuplicated(this.document, this.selectedYear)
        if(duplicated) {
          this.error = 'Mãe já está inscrita'
          this.dialog = false
          this.loading = false
          return
        }
        this.dialog = true
      },
      cancel() {
        this.loading = false
        this.dialog = false
      },
      clearError() {
        this.error = ''
      }
    },
  }
</script>
